import React from "react";
import "./style.scss";
import casinoDaysTwoLines from "../../images/Casino-Days-Logo-Two-Lines-Gradient-Text-600x372.png";
import casinoDaysTwoLinesWhite from "../../images/Casino-Days-Logo-Two-Lines-White-Text-600x372.png";
import casinoDaysTwoLinesBg from "../../images/Casino-Days-Two-Lines-Gradient-BG-600x600.png";
import casinoDaysTwoLinesBg2 from "../../images/Casino-Days-Two-Lines-Gradient-BG-800x480.png";
import casinoDaysTwoLinesWhiteBg from "../../images/Casino-Days-Two-Lines-White-BG-600x600.png";
import casinoDaysTwoLinesWhiteBg2 from "../../images/Casino-Days-Two-Lines-White-BG-800x480.png";
import luckySpinsTwoLinesBlack from "../../images/Lucky-Spins-Logo-Two-Lines-Black-Text-480x280.png";
import luckySpinsTwoLinesYellow from "../../images/Lucky-Spins-Logo-Two-Lines-Yellow-Text-480x280.png";
import luckySpinsBGYellow from "../../images/Lucky-Spins-Two-Lines-BG-Yellow-600x600.png";
import luckySpinsBGYellow2 from "../../images/Lucky-Spins-Two-Lines-BG-Yellow-800x480.png";
import luckySpinsPattern600 from "../../images/Lucky-Spins-Two-Lines-Pattern-Yellow-600x600.png";
import luckySpinsPattern800 from "../../images/Lucky-Spins-Two-Lines-Pattern-Yellow-800x480.png";
import buustiKasinoWhite from "../../images/Buusti-Kasino-Logo-White-Text-628x280.png";
import buustiKasinoBlue from "../../images/Buusti-Kasino-Logo-Blue-Text-628x280.png";
import buustiKasinoBgBlue from "../../images/Buusti-Kasino-BG-Blue-600x600.png";
import buustiKasinoBgBlue2 from "../../images/Buusti-Kasino-BG-Blue-800x480.png";
import buustiKasinoBgWhite from "../../images/Buusti-Kasino-BG-White-600x600.png";
import buustiKasinoBgWhite2 from "../../images/Buusti-Kasino-BG-White-800x480.png";
import bigBoostGold from "../../images/Big-Boost-Logo-Two-Lines-Gold-Text-568x352.png";
import bigBoostRed from "../../images/Big-Boost-Logo-Two-Lines-Red-Text-568x352.png";
import bigBoostWhite from "../../images/Big-Boost-Logo-Two-Lines-White-Text-568x352.png";
import bigBoostBgBlue from "../../images/Big-Boost-Two-Lines-BG-Blue-600x600.png";
import bigBoostBgBlue2 from "../../images/Big-Boost-Two-Lines-BG-Blue-800x480.png";
import bigBoostPattern from "../../images/Big-Boost-Two-Lines-BG-Pattern-800x480.png";
import bigBoostBgRed from "../../images/Big-Boost-Two-Lines-BG-Red-600x600.png";
import bigBoostBgRed2 from "../../images/Big-Boost-Two-Lines-BG-Red-800x480.png";
import rakettiTransparentPink from "../../images/Raketti-Transparent-Pink-150x70.png";
import rakettiTransparentYellow from "../../images/Raketti-Transparent-Yellow-150x70.png";
import rakettiTransparentPink600 from "../../images/Raketti-Transparent-Pink-600x600.png";
import rakettiTransparentPink800 from "../../images/Raketti-Transparent-Pink-800x480.png";
import rakettiTransparentYellow600 from "../../images/Raketti-Transparent-Yellow-600x600.png";
import rakettiTransparentYellow800 from "../../images/Raketti-Transparent-Yellow-800x480.png";


export const Downloads = () => {
  return (
    <div className="logos-content">
      <div className="logos-header">
        <h5 className="logos-title">
          Casino Days Logos
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={casinoDaysTwoLines} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={casinoDaysTwoLines} download='casinoDaysTwoLines'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={casinoDaysTwoLinesWhite} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={casinoDaysTwoLinesWhite} download='casinoDaysTwoLinesWhite'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={casinoDaysTwoLinesBg} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={casinoDaysTwoLinesBg} download='casinoDaysTwoLinesBg'>
            <button type='button' className='brand_button'>Download </button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={casinoDaysTwoLinesBg2} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={casinoDaysTwoLinesBg2} download='casinoDaysTwoLinesBg2'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={casinoDaysTwoLinesWhiteBg} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={casinoDaysTwoLinesWhiteBg} download='casinoDaysTwoLinesWhiteBg'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={casinoDaysTwoLinesWhiteBg2} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={casinoDaysTwoLinesWhiteBg2} download='casinoDaysTwoLinesWhiteBg2'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
      </div>
      <div className="logos-header">
        <h5 className="logos-title">
          Lucky Spins Logos
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={luckySpinsTwoLinesBlack} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={luckySpinsTwoLinesBlack} download='luckySpinsTwoLinesBlack'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={luckySpinsTwoLinesYellow} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={luckySpinsTwoLinesYellow} download='luckySpinsTwoLinesYellow'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={luckySpinsPattern600} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={luckySpinsPattern600} download='luckySpinsPattern600'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={luckySpinsPattern800} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={luckySpinsPattern800} download='luckySpinsPattern800'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={luckySpinsBGYellow} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={luckySpinsBGYellow} download='luckySpinsBGYellow'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={luckySpinsBGYellow2} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={luckySpinsBGYellow2} download='luckySpinsBGYellow2'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
      </div>
      <div className="logos-header">
        <h5 className="logos-title">
          Buusti Kasino Logos
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={buustiKasinoWhite} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={buustiKasinoWhite} download='buustiKasinoWhite'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={buustiKasinoBlue} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={buustiKasinoBlue} download='buustiKasinoBlue'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={buustiKasinoBgBlue} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={buustiKasinoBgBlue} download='buustiKasinoBgBlue'>
            <button type='button' className='brand_button'>Download </button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={buustiKasinoBgBlue2} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={buustiKasinoBgBlue2} download='buustiKasinoBgBlue2'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={buustiKasinoBgWhite} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={buustiKasinoBgWhite} download='buustiKasinoBgWhite'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={buustiKasinoBgWhite2} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={buustiKasinoBgWhite2} download='buustiKasinoBgWhite2'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
      </div>
      <div className="logos-header">
        <h5 className="logos-title">
          Big Boost Logos
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostGold} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostGold} download='bigBoostGold'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostRed} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostRed} download='bigBoostRed'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostWhite} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostWhite} download='bigBoostWhite'>
            <button type='button' className='brand_button'>Download </button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostBgBlue} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostBgBlue} download='bigBoostBgBlue'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostBgBlue2} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostBgBlue2} download='bigBoostBgBlue2'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostPattern} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostPattern} download='bigBoostPattern'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostBgRed} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostBgRed} download='bigBoostBgRed'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={bigBoostBgRed2} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={bigBoostBgRed2} download='bigBoostBgRed2'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          
        </div>
      </div>
      <div className="logos-header">
        <h5 className="logos-title">
          Raketti Logos
        </h5>
      </div>
      <div className="row">
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rakettiTransparentPink} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rakettiTransparentPink} download='rakettiTransparentPink'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rakettiTransparentYellow} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rakettiTransparentYellow} download='rakettiTransparentYellow'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rakettiTransparentPink600} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rakettiTransparentPink600} download='rakettiTransparentPink600'>
            <button type='button' className='brand_button'>Download </button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rakettiTransparentPink800} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rakettiTransparentPink800} download='rakettiTransparentPink800'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rakettiTransparentYellow600} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rakettiTransparentYellow600} download='rakettiTransparentYellow600'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
        <div className="col-lg-2 col-12 mb-5 logos_align">
          <div className="brands_height">
            <img src={rakettiTransparentYellow800} alt='logo' width='60%' className="mb-3" />
          </div>
          <br />
          <a href={rakettiTransparentYellow800} download='rakettiTransparentYellow800'>
            <button type='button' className='brand_button'>Download</button>
          </a>
        </div>
      </div>
    </div>
  );
};
